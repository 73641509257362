import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Button } from "antd";

import { FormList } from "./form";
import { IOrder } from "interfaces";

export const OrderCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IOrder>();

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      headerButtons={<Button onClick={() => history.back()}>Back</Button>}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
          serviceType: "Normal",
          bagCodes: [""],
          bags: 1,
        }}
      >
        <FormList formProps={formProps} type="create" />
      </Form>
    </Create>
  );
};
