import React from "react";
import { Refine } from "@refinedev/core";
import { RefineKbarProvider } from "@refinedev/kbar";
import routerProvider from "@refinedev/react-router-v6/legacy";
import { notificationProvider, Layout, ErrorComponent } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { authProvider } from "authProvider";
import { dataProvider } from "dataProvider";

import "dayjs/locale/de";

import { OrderList, OrderCreate, OrderEdit, OrderShow } from "./pages/orders";
import { AuthPage } from "./pages/auth";
import { StoreCreate, StoreEdit, StoreList } from "./pages/stores";
import { ShopifyOrderList, ShopifyOrderShow } from "pages/shopify-orders";
import { useTranslation } from "react-i18next";
import { Header, Title } from "components";

import "@refinedev/antd/dist/reset.css";
import { ShopifyStoreList, ShopifyStoreShow } from "pages/shopify-stores";

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <RefineKbarProvider>
      <Refine
        legacyRouterProvider={{
          ...routerProvider,
          routes: [
            {
              path: "/register",
              element: (
                <AuthPage
                  type="register"
                  formProps={{
                    initialValues: {},
                  }}
                />
              ),
            },
            {
              path: "/forgot-password",
              element: <AuthPage type="forgotPassword" />,
            },
            {
              path: "/update-password",
              element: <AuthPage type="updatePassword" />,
            },
          ],
        }}
        dataProvider={dataProvider}
        legacyAuthProvider={authProvider}
        i18nProvider={i18nProvider}
        LoginPage={() => (
          <AuthPage
            type="login"
            rememberMe={false}
            registerLink={false}
            formProps={{
              initialValues: {},
            }}
          />
        )}
        Title={Title}
        Header={Header}
        Layout={Layout}
        options={{
          syncWithLocation: true,
          warnWhenUnsavedChanges: true,
        }}
        resources={[
          {
            name: "orders",
            list: OrderList,
            show: OrderShow,
            create: OrderCreate,
            edit: OrderEdit,
            canDelete: true,
            icon: <Icons.ShoppingOutlined />,
          },
          {
            name: "stores",
            list: StoreList,
            edit: StoreEdit,
            create: StoreCreate,
            icon: <Icons.ShopOutlined />,
          },
          {
            name: "shopify-orders",
            list: ShopifyOrderList,
            show: ShopifyOrderShow,
          },
          {
            name: "shopify-stores",
            list: ShopifyStoreList,
            show: ShopifyStoreShow,
          },
          // {
          //     name: "admins",
          //     edit: AdminEdit,
          //     icon: <Icons.UsergroupAddOutlined />,
          // },
          // {
          //     name: "users",
          //     list: UserList,
          //     create: UserCreate,
          //     edit: UserEdit,
          //     show: UserShow,
          //     icon: <Icons.UsergroupAddOutlined />,
          // },
          // {
          //     name: "products",
          //     list: ProductList,
          //     icon: <PizzaIcon />,
          // },
          // {
          //     name: "categories",
          //     list: CategoryList,
          //     create: CategoryCreate,
          // },
        ]}
        notificationProvider={notificationProvider}
        catchAll={<ErrorComponent />}
      />
    </RefineKbarProvider>
  );
};

export default App;
