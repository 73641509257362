import {
  useTranslate,
  IResourceComponentsProps,
  useExport,
  useNavigation,
  HttpError,
  CrudFilters,
  useSelect,
  getDefaultFilter,
} from "@refinedev/core";

import { List, useTable } from "@refinedev/antd";
import {
  Table,
  Popover,
  Button,
  Row,
  Col,
  Card,
  FormProps,
  Form,
  Select,
} from "antd";

import {
  IShopifyOrder,
  IShopifyStore,
  IShopifyStoreFilterVariables,
} from "interfaces";

export const ShopifyStoreList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    sorters: sorter,
    filters,
    searchFormProps,
  } = useTable<IShopifyStore, HttpError, IShopifyStoreFilterVariables>({
    resource: "shopify-stores",

    onSearch: (params) => {
      const filters: any = [];

      const { name, status } = params;

      filters.push({
        field: "name",
        operator: "eq",
        value: name,
      });

      filters.push({
        field: "status",
        operator: "eq",
        value: status,
      });

      return filters;
    },
  });

  const t = useTranslate();
  const { show } = useNavigation();

  useExport<IShopifyStore>({
    filters,
    pageSize: 50,
    maxItemCount: 50,

    mapData: (item) => {
      return {
        id: item.id,
        name: item.name,
        balance: item.balance,
        status: item.status,
      };
    },

    sorters: sorter,
  });

  return (
    <Row gutter={[16, 16]}>
      <Col
        xl={6}
        lg={24}
        xs={24}
        style={{
          marginTop: "52px",
        }}
      >
        <Card title={t("orders.filter.title")}>
          <Filter formProps={searchFormProps} filters={filters || []} />
        </Card>
      </Col>{" "}
      <Col xl={18} xs={24}>
        <List>
          <Table {...tableProps} rowKey="name">
            {/* <Table.Column dataIndex="id" title="ID" align="center" /> */}
            <Table.Column
              key="name"
              dataIndex="name"
              title={t("Store name")}
              render={(name, record: IShopifyStore) => (
                <Popover title={record.name} trigger="hover">
                  <Button
                    onClick={() => show("shopify-stores", record.id)}
                    type="link"
                  >
                    {name}
                  </Button>
                </Popover>
              )}
            />
            <Table.Column<IShopifyStore>
              key="status"
              dataIndex={"status"}
              title={t("Store status")}
              render={(value) => value}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
  props
) => {
  const t = useTranslate();
  const { formProps, filters } = props;
  const shopifyStoreNameProps = useSelect<IShopifyOrder>({
    resource: "shopify-stores",
    optionLabel: "name" as any,
    optionValue: "name" as any,
    defaultValue: getDefaultFilter("store.name", filters),

    pagination: {
      mode: "server",
    },
  });

  const shopifyStoreStatusProps = useSelect<IShopifyOrder>({
    resource: "shopify-stores",
    optionLabel: "status" as any,
    optionValue: "status" as any,
    defaultValue: getDefaultFilter("store.status", filters),

    pagination: {
      mode: "server",
    },
  });

  return (
    <Form
      layout="vertical"
      {...formProps}
      initialValues={{
        name: getDefaultFilter("store.name", filters),
        status: getDefaultFilter("store.status", filters),
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={"Store Status"} name="status">
            <Select
              options={shopifyStoreStatusProps.options}
              allowClear
              placeholder={t("orders.filter.status.placeholder")}
              showSearch
            ></Select>
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={"Store Name"} name="name">
            <Select
              options={shopifyStoreNameProps.options}
              allowClear
              placeholder={"Search Orders"}
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block>
              {t("orders.filter.submit")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
