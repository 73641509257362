import {
  useTranslate,
  IResourceComponentsProps,
  useExport,
  useNavigation,
  HttpError,
  CrudFilters,
  useSelect,
  getDefaultFilter,
} from "@refinedev/core";

import { List, useTable } from "@refinedev/antd";
import {
  Table,
  Popover,
  Button,
  Row,
  Col,
  Card,
  FormProps,
  Form,
  Select,
} from "antd";

import { OrderStatus } from "components";
import { IShopifyOrder, IShopifyFilterVariables } from "interfaces";
import { ShopifyActions } from "components/shopifyActions";

export const ShopifyOrderList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    sorters: sorter,
    filters,
    searchFormProps,
  } = useTable<IShopifyOrder, HttpError, IShopifyFilterVariables>({
    resource: "shopify-orders",

    onSearch: (params) => {
      const filters: any = [];

      const { storeName, shopifyOrderID, customerEmail, customerPhone } =
        params;

      filters.push({
        field: "storeName",
        operator: "eq",
        value: storeName,
      });

      filters.push({
        field: "shopifyOrderID",
        operator: "eq",
        value: shopifyOrderID,
      });

      filters.push({
        field: "customerEmail",
        operator: "eq",
        value: customerEmail,
      });

      filters.push({
        field: "customerPhone",
        operator: "eq",
        value: customerPhone,
      });

      return filters;
    },
  });

  console.log("searchFormProps:", searchFormProps);
  console.log("component filters:", filters);

  const t = useTranslate();
  const { show } = useNavigation();

  useExport<IShopifyOrder>({
    filters,
    pageSize: 50,
    maxItemCount: 50,

    mapData: (item) => {
      return {
        storeName: item.storeName,
        orderStatus: item.orderStatus,
        customerEmail: item.customerEmail,
        customerPhone: item.customerPhone,
        shopifyOrderID: item.shopifyOrderID,
      };
    },

    sorters: sorter,
  });

  return (
    <Row gutter={[16, 16]}>
      <Col
        xl={6}
        lg={24}
        xs={24}
        style={{
          marginTop: "52px",
        }}
      >
        <Card title={t("orders.filter.title")}>
          <Filter formProps={searchFormProps} filters={filters || []} />
        </Card>
      </Col>{" "}
      <Col xl={18} xs={24}>
        <List>
          <Table {...tableProps} rowKey="id">
            {/* <Table.Column dataIndex="id" title="ID" align="center" /> */}
            <Table.Column
              key="id"
              dataIndex="id"
              title={t("orders.fields.orderNumber")}
              render={(value, record: IShopifyOrder) => (
                <Popover title={record.id} trigger="hover">
                  <Button
                    onClick={() => show("shopify-orders", value)}
                    type="link"
                  >
                    Shopify Order ID
                  </Button>
                </Popover>
              )}
            />
            <Table.Column<IShopifyOrder>
              key="orderStatus"
              dataIndex={"orderStatus"}
              title={t("orders.fields.status")}
              render={(value) => {
                return <OrderStatus status={value} />;
              }}
              // sorter
            />
            <Table.Column
              align="right"
              key="shippingCost"
              dataIndex="shippingCost"
              title={"Shipping cost"}
              render={(_value, record: IShopifyOrder) => {
                if (!record.shippingCost) {
                  return "";
                } else {
                  return `${record.currency}${record.shippingCost}`;
                }
              }}
            />
            <Table.Column
              key="storeName"
              dataIndex={"storeName"}
              title={t("orders.fields.store")}
              render={(value) => value}
            />
            <Table.Column
              key="customerName"
              dataIndex={"customerName"}
              title={t("orders.fields.user")}
              render={(value) => value}
            />
            <Table.Column
              key="customerEmail"
              dataIndex="customerEmail"
              title="Email"
              render={(value) => (value ? value : "None")}
            />{" "}
            <Table.Column
              key="customerPhone"
              dataIndex="customerPhone"
              title="Phone Number"
              render={(value) => (value ? value : "None")}
            />
            <Table.Column<IShopifyOrder>
              fixed="right"
              title={t("table.actions")}
              dataIndex="actions"
              key="actions"
              align="center"
              render={(_value, record) => <ShopifyActions record={record} />}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
  props
) => {
  const t = useTranslate();
  const { formProps, filters } = props;
  const shopifyOrderIdProps = useSelect<IShopifyOrder>({
    resource: "shopify-orders",
    optionLabel: "shopifyOrderID",
    optionValue: "shopifyOrderID",
    defaultValue: getDefaultFilter("order.shopifyOrderID", filters),

    pagination: {
      mode: "server",
    },
  });
  const storeNameProps = useSelect<IShopifyOrder>({
    resource: "shopify-orders",
    optionLabel: "storeName",
    optionValue: "storeName",
    defaultValue: getDefaultFilter("order.storeName", filters),

    pagination: {
      mode: "server",
    },
  });

  const customerEmailProps = useSelect<IShopifyOrder>({
    resource: "shopify-orders",
    optionLabel: "customerEmail",
    optionValue: "customerEmail",
    defaultValue: getDefaultFilter("order.customerEmail", filters),

    pagination: {
      mode: "server",
    },
  });

  const customerPhoneProps = useSelect<IShopifyOrder>({
    resource: "shopify-orders",
    optionLabel: "customerPhone",
    optionValue: "customerPhone",
    defaultValue: getDefaultFilter("order.customerPhone", filters),

    pagination: {
      mode: "server",
    },
  });

  return (
    <Form
      layout="vertical"
      {...formProps}
      initialValues={{
        shopifyOrderID: getDefaultFilter("order.shopifyOrderID", filters),
        storeName: getDefaultFilter("order.storeName", filters),
        customerEmail: getDefaultFilter("order.customerEmail", filters),
        customerPhone: getDefaultFilter("order.customerPhone", filters),
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={"Order Status"} name="orderStatus">
            <Select
              options={[
                { label: "Delivered", value: "Delivered" },
                { label: "Not Delivered", value: "Not Delivered" },
                { label: "Out for Delivery", value: "Out for Delivery" },
                { label: "Order Packaged", value: "Order Packaged" },
                { label: "Order Received", value: "Order Received" },
              ]}
              allowClear
              placeholder={t("orders.filter.status.placeholder")}
              showSearch
            ></Select>
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={"Shopify Order ID"} name="shopifyOrderID">
            <Select
              options={shopifyOrderIdProps.options}
              allowClear
              placeholder={"Search Orders"}
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={"Store name"} name="storeName">
            <Select
              options={storeNameProps.options}
              allowClear
              placeholder={"Search store names"}
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={"Customer email"} name="customerEmail">
            <Select
              options={customerEmailProps.options}
              allowClear
              placeholder={"Search customer emails"}
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Col xl={24} md={8} sm={12} xs={24}>
            <Form.Item label={"Customer phone"} name="customerPhone">
              <Select
                options={customerPhoneProps.options}
                allowClear
                placeholder={"Search customer phone numbers"}
                showSearch
                filterOption={(input, option) =>
                  String(option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>{" "}
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block>
              {t("orders.filter.submit")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
