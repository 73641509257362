import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";

import { Button, Col, Modal, Typography } from "antd";
import { useState } from "react";

import { Input } from "antd";

const { TextArea } = Input;
const { Title } = Typography;

/* PDF component below. */
interface PdfProps {
  deliveryAddress: string;
  customerName: string;
  serviceType: string;
  deliveryNotes: string;
  orderNumber: string;
  storeName: string;
  adminNotes: string;
  unipass?: string;
}

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  title: {
    textIndent: 7,
    paddingLeft: 15,
    fontSize: 15,
    lineHeight: 1.5,
    marginTop: 3,
    marginBottom: 1,
    fontWeight: "bold",
    fontFamily: "Open Sans",
  },
  heading: {
    textIndent: 7,
    paddingLeft: 15,
    textAlign: "left",
    fontSize: 13,
    lineHeight: 1.3,
    marginTop: 3,
    fontWeight: "bold",
    fontFamily: "Open Sans",
  },
  textBody: {
    textIndent: 7,
    paddingLeft: 15,
    textAlign: "left",
    fontSize: 13,
    lineHeight: 1.3,
    marginTop: 1,
    marginBottom: 3,
    fontWeight: 400,
    fontFamily: "Open Sans",
  },
  row: {
    borderColor: "black",
    borderWidth: 3,
    width: 297,
  },
  divider: {
    flexDirection: "column",
    borderColor: "black",
    borderWidth: 1,
  },
  acrow: {
    flexDirection: "row",
  },
  rowItem: {
    flex: 1,
  },
  image: {
    width: 55,
    height: 55,
  },
});

const Pdf = ({
  deliveryAddress,
  customerName,
  serviceType,
  deliveryNotes,
  orderNumber,
  storeName,
  adminNotes,
  unipass,
}: PdfProps) => {
  return (
    <Document>
      <Page size="A6" style={styles.page}>
        <View style={styles.acrow}>
          <View style={styles.page}>
            <View style={styles.row}>
              <Text style={styles.title}>SHIPPING LABEL</Text>

              <View style={styles.acrow}>
                <View style={styles.rowItem}>
                  <View style={styles.divider}>
                    <Text style={styles.heading}>Store name</Text>
                    <Text style={styles.textBody}>{storeName}</Text>
                  </View>
                </View>
                <View style={styles.rowItem}>
                  <View style={styles.divider}>
                    <Text style={styles.heading}>Customer name</Text>
                    <Text style={styles.textBody}>{customerName}</Text>
                  </View>
                </View>
              </View>

              <View style={styles.acrow}>
                <View style={styles.rowItem}>
                  <View style={styles.divider}>
                    <Text style={styles.heading}>Order number</Text>
                    <Text style={styles.textBody}>{orderNumber}</Text>
                  </View>
                </View>
                <View style={styles.rowItem}>
                  <View style={styles.divider}>
                    <Text style={styles.heading}>Service type</Text>
                    <Text style={styles.textBody}>{serviceType}</Text>
                  </View>
                </View>
              </View>

              <View style={styles.acrow}>
                <View style={styles.rowItem}>
                  <View style={styles.divider}>
                    <Text style={styles.heading}>Address</Text>
                    <Text style={styles.textBody}>{deliveryAddress}</Text>
                  </View>
                </View>
                {unipass && (
                  <View style={styles.rowItem}>
                    <View style={styles.divider}>
                      <Text style={styles.heading}>Unipass number</Text>
                      <Text style={styles.textBody}>{unipass}</Text>
                    </View>
                  </View>
                )}
              </View>

              <View style={styles.divider}>
                <Text style={styles.heading}>Delivery notes</Text>
                <Text style={styles.textBody}>{deliveryNotes}</Text>
              </View>

              <Text style={styles.heading}>Store notes</Text>
              <Text style={styles.textBody}>{adminNotes}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

/* React component below */

// Describes function signature
interface CloseProp {
  (): void;
}

interface GeneratePdfProps {
  open: boolean;
  close: CloseProp;
  order: any;
  customer: any;
  storeName: string;
}

const NOTE_LIMIT = 135;

const GeneratePdf = ({
  open,
  close,
  order,
  customer,
  storeName,
}: GeneratePdfProps) => {
  const [text, setTextInternal] = useState("");

  const setText = (text: string) => {
    if (text.length <= NOTE_LIMIT) {
      setTextInternal(text);
    }
  };

  const address = `${order.deliveryAddressType}
${order.deliveryLineAddress}
${order.deliveryCity}
${order.deliveryAddressPostalCode}
${order.deliveryCountry || ""}`;

  const pdf = (
    <Pdf
      deliveryAddress={address}
      customerName={customer ? customer.name : ""}
      serviceType={order.serviceType}
      deliveryNotes={order.notes || ""}
      orderNumber={order.orderNumber}
      storeName={storeName}
      adminNotes={text}
      unipass={order.unipass}
    />
  );

  return (
    <Modal
      title="Print Delivery Label"
      open={open}
      onCancel={close}
      width={"25vw"}
      bodyStyle={{ height: "13vh" }}
      footer={
        <>
          <PDFDownloadLink document={pdf} fileName="order.pdf">
            {({}) => <Button>Download</Button>}
          </PDFDownloadLink>

          <Button style={{ marginLeft: 9 }} onClick={close}>
            Close
          </Button>
        </>
      }
    >
      <Col>
        <Title level={5} style={{ fontSize: 13 }}>
          Additional notes
        </Title>
        <Col>
          <TextArea value={text} onChange={(e) => setText(e.target.value)} />
        </Col>
        <p
          style={{
            flexDirection: "row-reverse",
            display: "flex",
            marginTop: 7,
          }}
        >
          {`${text.length}/${NOTE_LIMIT}`}
        </p>
      </Col>
    </Modal>
  );
};

export default GeneratePdf;
