import { IResourceComponentsProps, useNavigation } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form } from "antd";

import { FormList } from "./form";
import { IStore } from "interfaces";
import { dataProvider } from "dataProvider";

export const StoreCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IStore>({
    warnWhenUnsavedChanges: false,
  });
  const { list } = useNavigation();

  const emailContract = async (variables: any) => {
    // Register/create store.
    await dataProvider.create({
      resource: "stores",
      variables,
      metadata: undefined,
    });

    // Send email
    const form = formProps.form;
    if (form) {
      const managementEmail = form.getFieldValue("email");
      const representativeEmail = form.getFieldValue("representativeEmail");
      const retailerName = form.getFieldValue("title");
      const retailerLegalName = form.getFieldValue("legalTitle");
      const representativeName = form.getFieldValue("representativeName");
      const { country, city, address2, postal_code } =
        form.getFieldValue("address");
      const addressParts = (address2 as string).split(",");
      const address = addressParts[0] + addressParts[1];
      const params = {
        managementEmail,
        representativeEmail,
        retailerName,
        retailerLegalName,
        representativeName,
        country,
        city,
        postalCode: postal_code,
        address,
      };
      await fetch("https://benevolent-syrniki-a2ad06.netlify.app/api/email", {
        method: "POST",
        body: JSON.stringify(params),
      });
      list("stores");
    }
  };

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          phoneCode: "+44",
        }}
        onFinish={(values: any) => emailContract(values)}
      >
        <FormList formProps={formProps} type="create" />
      </Form>
    </Create>
  );
};
