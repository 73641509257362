import { useNavigation } from "@refinedev/core";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Dropdown, Menu } from "antd";
import { IShopifyOrder } from "interfaces";

const { FormOutlined, FolderViewOutlined } = Icons;

type ShopifyOrderProps = {
  record: IShopifyOrder;
};

export const ShopifyActions: React.FC<ShopifyOrderProps> = ({ record }) => {
  const { edit, show } = useNavigation();
  const moreMenu = (record: IShopifyOrder) => (
    <Menu
      mode="vertical"
      onClick={({ domEvent }) => domEvent.stopPropagation()}
    >
      <Menu.Item
        key="2"
        style={{
          fontWeight: 500,
        }}
        icon={
          <FolderViewOutlined
            style={{
              color: "green",
            }}
          />
        }
        onClick={() => show("shopify-orders", record.id)}
      >
        View Order
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={moreMenu(record)} trigger={["click"]}>
      <Icons.MoreOutlined
        onClick={(e) => e.stopPropagation()}
        style={{
          fontSize: 24,
        }}
      />
    </Dropdown>
  );
};
